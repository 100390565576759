<template>
  <div class="login_container">
    <div style="width: 100%" v-for="item in list" :key="item.id">
      <van-cell :title="'就诊人姓名：' + userName">
        <template slot="right-icon">
          <span style="color: red">已就诊</span>
        </template>
      </van-cell>
      <van-cell-group>
          <div style="width: 100%;">
            <van-cell title="就诊信息" style="font-weight:700"></van-cell>
            <van-cell title="就诊科室">{{item.deptName}}</van-cell>
            <van-cell title="就诊类别">{{item.reglevlName}}</van-cell>
            <van-cell title="就诊医生">{{item.doctName}}</van-cell>
            <van-cell>
              <template slot="icon">预约时间</template>
              <template slot="default">
                <div class="slot_align">{{ item.regTime }}</div>
              </template>
            </van-cell>
            <van-cell>
              <template slot="icon">就诊时间</template>
              <template slot="default">
                <div class="slot_align">{{item.seeTime}}</div>
              </template>
            </van-cell>
            <van-cell title="就诊序号">
              <span style="color:#57c4b7;">{{item.clinicNO}}</span>
            </van-cell>
          </div>
          <div style="width: 100%;">
            <van-cell title="就诊人信息" style="font-weight:700"></van-cell>
            <!-- <van-cell title="病历号">
              <span style="color:#57c4b7;">{{item.outPatientId}}</span>
            </van-cell>
            <van-cell title="就诊卡">
              <span style="color:#57c4b7;">{{item.markNo}}</span>
            </van-cell> -->
            <van-cell title="订单号">
              <span style="color:#57c4b7;">{{item.orderId}}</span>
            </van-cell>
            <van-cell title="费用">
              <span style="color:#57c4b7;">{{item.totalCost}}</span>元
            </van-cell>
          </div>
        </van-cell-group>
    </div>

    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import store from "../../store";
var nowDate = new Date();
// const regNo = null;

export default {
  data() {
    return {
      userName: "",
      list: [],
    };
  },
  created() {
    this.userName = store.getters["store/userName"];
    this.getRegL();
  },
  methods: {
    async getRegL() {
      // let startTime=window.sessionStorage.getItem("regSTime");
      // let endTime=window.sessionStorage.getItem("regETime");
      let regNos = store.getters["store/regNo"];
      var Date = {
        year: nowDate.getFullYear(),
        month:
          nowDate.getMonth() + 1 < 10
            ? "0" + (nowDate.getMonth() + 1)
            : "" + (nowDate.getMonth() + 1),
        date:
          nowDate.getDate() < 10
            ? "0" + nowDate.getDate()
            : "" + nowDate.getDate(),
      };
      var Today = Date.year + "-" + Date.month + "-" + Date.date;
      let userId = store.getters["store/userId"];
      // let idcard = store.getters["store/idcard"];
      console.log(userId);
      // let patientid = store.getters["store/patientid"]; //声明病历号
      await  ajax
        // .get("/Api/RegistrationService/GetRegistRecords?regno="+regNos)
        .post("/Api/RegistrationService/QueryRegistRecords", {
          // userID: userId,
          userID:userId,
          // userName:this.userName,
          // state:[2,3],
          startDate: Today + " 00:00:00",
          endDate: Today + " 23:59:59",
        })
        .then((rep) => {
          // console.log(rep.data.result.regDetails);
          let listss = rep.data.result.regRecordLists;
          // for(let i=0;i<listss.length; i++){
          //   listss.scheduleDate = listss.scheduleDate.substring(0,10);
          //   if(listss.drId == ''){
          //     listss.regLevelName = '普通号'
          //     listss.drName = '门诊医生|医师'
          //   }else{
          //      listss.regLevelName = '专家号'
          //   }
          //   let name = listss.drName.split("|");
          //   listss.docname = name[0];
          // // }
          // // this.list = listss;
          // console.log(listss);
          for (let i = 0; i < listss.length; i++) {
            const element = listss[i];
            if(element.clinicNO == regNos){
              this.list.push(element);
            }
          }
          
          console.log(listss);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.slot_align {
  color: #969799;
  text-align: right;
}
</style>